@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Lato:wght@400;700&family=Merriweather:wght@400;700&display=swap");

.App {
  text-align: center;
  background-color: #121826;
  min-height: 100vh;
}

html,
body {
  background-color: #121826; /* Sets the background color for the entire page */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  font-family: "Merriweather", serif;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Global styles */
body {
  /* font-family: Arial, sans-serif; */
  font-family: "Merriweather", serif;
  /* font-family: "Lato", sans-serif; */
  font-size: 15px;
  line-height: 1.5;
  background-color: #807a79; /* Background color */
  color: #fce7cc; /* Text color */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #f8e8cf;
}

/* Headers */
h1 {
  font-size: 25px;
  color: #f77f3d;
}

h2 {
  font-size: 20px;
  color: #ed782f;
}

h3 {
  font-size: 15px;
  color: #f08c42;
}

h4 {
  font-size: 10px;
  color: #f0b96d;
}

h5 {
  font-size: 0.25rem;
  color: #ffc77d;
}

h6 {
  font-size: 1rem;
  color: #f7dfb5;
}

/* Paragraphs */
p {
  font-family: "Merriweather", serif;
  margin: 10px 0;
  color: #e5d7c3;
}

/* Lists */
ool,
ul {
  margin: 15px 0;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

/* Strong (Bold) Text */
strong {
  font-weight: bold;
  color: #f77f3d;
}

/* blockquote {
  margin: 20px 0;
  padding: 15px 20px;
  border-left: 5px solid #f08c42;
  background-color: #1e2738;
  font-style: italic;
  color: #ffc77d;
} */

/* Italics Text */
em {
  font-style: italic;
  color: #ffc77d; /* Italics color */
}

/* Links */
a {
  background-image: linear-gradient(
    to right,
    #ed782f,
    #ed782f 50%,
    #f0b96d 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 1px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.2s ease-in-out;
}

a:before {
  content: "";
  background: #ed782f;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2.5px;
  transition: all 0.2s ease-in-out;
}

a:hover {
  background-position: 0;
}

a:hover::before {
  width: 100%;
}

/* New :active styles for click effect */
a:active {
  background-image: linear-gradient(
    to right,
    #f0b96d,
    #f0b96d 50%,
    #ed782f 50%
  );
  color: #fff; /* Optional: change text color on click */
}

a:active:before {
  background: #f0b96d; /* Change the underline color on active/click */
  height: 2.5px; /* Ensure the underline height matches */
}

/* Scrollbar styles */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ff6f00 #131825;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: #212936; /* Scrollbar track color */
}

*::-webkit-scrollbar-thumb {
  background-color: #ff6f00; /* Scrollbar thumb color */
  border-radius: 6px;
  border: 3px solid #807a79; /* Creates padding around the thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background: #ffc77d; /* Scrollbar thumb color on hover */
}

/* Default styles for the source section */
#drawer-navigation {
  width: 25%; /* Adjust as needed */
  min-width: 250px;
  max-width: 400px;
  transition: transform 0.3s ease;
}

#drawer-navigation > button {
  background-color: #ff6f00;
  color: white;
  border: none;
  padding: 20px 10px; /* Adjust for semi-circle shape */
  cursor: pointer;
  border-radius: 0 50% 50% 0; /* Semi-circle shape */
}

/* Arrow icon rotation when collapsed */
#drawer-navigation > button > svg.rotate-180 {
  transform: rotate(180deg);
}

/* Media query for smaller screens */
@media (max-width: 1100px) {
  #drawer-navigation {
    transform: translateX(100%);
  }
}

.additional-styles-for-collapsed {
  /* Example styles */
  align-items: center; /* Center horizontally in flex container */
  /* Additional styling to increase size or other properties */
}

.wider-output-style {
  /* Increase the width of Output containers */
  width: 80%; /* Adjust as needed */
}

.source-card {
  border: 2px solid #e2e8f0; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Increased padding for more height */
  margin-bottom: 15px; /* Spacing between cards */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth transitions for hover effects */
  height: 175px; /* Fixed height */
  overflow: hidden;
}

.source-card:hover {
  transform: translateY(-2px); /* Slightly raise the card on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow on hover */
}

.source-card p {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}

.pagination-controls {
  padding-bottom: 15px; /* Adjust space at the bottom */
}

.pagination-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%; /* Circular buttons */
  background-color: #f8e8cf; /* Light background color */
  color: #212936; /* Dark arrow color */
  border: none;
  cursor: pointer;
}

.pagination-arrow svg {
  width: 15px;
  height: 15px;
}

.pagination-arrow:hover {
  background-color: #ed782f;
}

.page-number {
  font-size: 16px;
  color: #f8e8cf;
}

@media (max-width: 1100px) {
  .source-expanded #drawer-navigation {
    transform: translateX(0);
    width: 100%;
  }

  .source-expanded .controller-output-container {
    display: none;
  }
}

.textarea-style {
  transition: all 0.2s ease;
  border-radius: 12px; /* More rounded corners */
}

.textarea-style:focus {
  transform: scale(1.009); /* Slight enlargement on focus */
  border-color: #ed782f; /* Change border color on focus */
  box-shadow: 0 0 10px #ed782f; /* Add a shadow on focus */
}

.submit-button {
  transition: all 0.2s ease;
}

.submit-button:active {
  transform: scale(0.9); /* Slight shrink effect on click */
}

.global-heading {
  margin-bottom: 0.5rem; /* mb-2 */
  font-size: 1.125rem; /* text-lg */
  font-weight: 600; /* font-semibold */
  color: #1a202c; /* text-gray-900 */
}

.global-heading.dark-mode {
  color: #fff; /* dark:text-white */
}

.global-list {
  max-width: 25rem; /* max-w-md */
  margin: 0;
  padding: 0;
  list-style-type: decimal; /* list-decimal */
  list-style-position: inside; /* list-inside */
  color: #718096; /* text-gray-500 */
}

.global-list-item {
  margin-bottom: 0.25rem; /* space-y-1 */
}

.global-list-item span {
  font-weight: 600; /* font-semibold */
  color: #1a202c; /* text-gray-900 */
}

.global-list-item.dark-mode span {
  color: #fff; /* dark:text-white */
}

/* Text Selection Styles */
::selection {
  background-color: #ff6f00;
  color: #fce7cc;
  text-shadow: none; /* Removes text shadow if any */
}

/* For Mozilla Firefox */
::-moz-selection {
  background-color: #ff6f00;
  color: #fce7cc;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle {
  width: 20px;
  height: 20px;
  margin: 5px;
  background-color: #ff6a00; /* Vibrant orange color */
  border-radius: 50%;
  display: inline-block;
}

.animate-loader {
  animation: bounce 1.2s infinite ease-in-out both;
}

.animation-delay-200 {
  animation-delay: 0.2s;
}

.animation-delay-400 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* CSS for the circles */
.circle {
  width: 16px;
  height: 16px;
  margin: 2px;
  background-color: #f3f3f3; /* Light grey color */
  border-radius: 50%;
  display: inline-block;
  animation: animate-loader 1.2s infinite ease-in-out both;
}

@keyframes animate-loader {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.animation-delay-200 {
  animation-delay: -0.32s;
}

.animation-delay-400 {
  animation-delay: -0.16s;
}

/* CSS for the text animation */
.typing-text {
  font-size: 1.5em;
  color: #f3f3f3; /* Light grey color */
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid #f3f3f3; /* Cursor color */
  animation: typing 4s steps(20, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #f3f3f3;
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.fill-[#ED782F] {
  fill: #ed782f;
}

.text-gray-200 {
  color: #e5e7eb;
}

.text-gray-600 {
  color: #4b5563;
}

/* Define the keyframes for the bounce-scale animation */
@keyframes bounce-scale {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05);
  }
  40% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1.02);
  }
  80% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

.bounce-scale {
  animation: bounce-scale 0.5s ease-in-out;
}

mark {
  @apply bg-gradient-to-r from-orange-300 via-yellow-200 to-orange-300 text-black font-medium px-1;
  box-decoration-break: clone;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  @apply inline;
}

.mark-rounded {
  @apply rounded-sm shadow-md;
}

.mark-custom {
  @apply text-black bg-gradient-to-br from-orange-300 to-orange-400 rounded-md px-1 py-0.5 shadow-sm;
}

.markdown-content ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-left: 1rem;
}

.markdown-content ol {
  list-style-type: decimal;
  padding-left: 1.5rem; /* Adjust padding as needed */
  margin-left: 1.5rem; /* Optional: Add margin for better spacing */
}

.markdown-content li {
  margin-bottom: 0.3rem; /* Optional: Add spacing between list items */
}

.citation-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: rgba(255, 140, 0, 0.2); /* light orange-gray */
  color: #ff8c00; /* orange */
  border-radius: 12px;
  font-weight: bold;
  font-size: 0.875rem; /* Slightly larger than normal text */
}

/* Hide increment arrows in number input fields */
.custom-year-input::-webkit-outer-spin-button,
.custom-year-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-year-input {
  -moz-appearance: textfield;
}
